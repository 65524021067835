import React from "react"
import Layout from "../components/layout"
import Gallery from "../components/Gallery"
import { StaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"

const keywords = [
  "профессиональная фотосессия в москве",
  "хайэнд ретушь",
  "профессиональная ретушь портрета",
  "high end ретушь",
  "студийный фотограф в москве",
  "журнальная ретушь",
  "студийная фотосессия в москве",
  "фотосессия в студии",
  "фотограф в москве",
  "лучший фотограф в москве",
  "лучший ретушер",
  "найти ретушера",
  "премиум фотосессия",
  "фотосессия на природе",
  "фотосессия на улице",
  "стрит съемка",
  "подводная фотосессия",
  "подводный фотограф",
  "рекламная съемка",
  "каталожная съемка",
  "как выбрать фотографа",
  "как найти фотографа",
  "портретный фотограф москва",
  "фотограф москва инстаграм",
  "фотограф девушка",
]

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query portfolioGallery {
          wordpressPage(wordpress_id: { eq: 122 }) {
            title
            content
            acf {
              gallery {
                id
                caption
                localFile {
                  childImageSharp {
                    original: fluid(maxWidth: 2000, quality: 100) {
                      src
                    }
                    fluid(maxWidth: 500, quality: 100) {
                      src
                    }
                  }
                }
              }
            }
          }
          site {
            id
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => {
        return (
          <Layout>
            <SEO
              title={data.site.siteMetadata.title}
              keywords={keywords}
              lang="ru_ru"
            />
            <Gallery imgs={data.wordpressPage.acf.gallery} />
          </Layout>
        )
      }}
    />
  )
}
