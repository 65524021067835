import React from "react"
import Image from "./Image"
import Masonry from "react-masonry-component"

const masonryOptions = {
  itemSelector: ".grid-item",
  columnWidth: ".grid-sizer",
  percentPosition: true,
}

function Gallery(props) {
  const { imgs, toggleLightbox } = props

  return (
    <Masonry
      className={"grid"}
      options={masonryOptions}
      disableImagesLoaded={false}
      updateOnEachImageLoad={true}
    >
      <div className="grid-sizer"></div>
      <div className="gutter-sizer"></div>
      {imgs.map((img, index) => {
        return (
          <Image
            key={index}
            src={img.src}
            toggleLightbox={toggleLightbox}
            imgKey={index}
          />
        )
      })}
    </Masonry>
  )
}

export default Gallery
