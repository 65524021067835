import React, { Component } from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import styled from "styled-components"
import PlainGallery from "./components/PlainGallery"
import "./style.css"



const GalleryWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.originalImages = []
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      dispImgs: [],
      originImgs: [],
    }

    this.toggleLightbox = this.toggleLightbox.bind(this)
  }

  componentDidMount() {
    const [dispImgs, originImgs] = this.props.imgs.reduce(
      (acc, img) => {
        acc[0].push({
          src: img.localFile.childImageSharp.fluid.src,
          caption: img.caption ? img.caption.replace(/<[^>]*>/gm, "") : "",
        })
        acc[1].push({
          source: {
            regular: img.localFile.childImageSharp.original.src,
          },
        })

        return acc
      },
      [[], []]
    )
    this.setState({ dispImgs, originImgs })
  }

  toggleLightbox(currentImage) {
    this.setState(state => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      currentImage,
    }))
  }

  render() {
    return (
      <GalleryWrapper>
        <PlainGallery
          imgs={this.state.dispImgs}
          toggleLightbox={this.toggleLightbox}
        />
        {this.state.lightboxIsOpen ? (
          <ModalGateway>
            <Modal onClose={this.toggleLightbox}>
              <Carousel
                currentIndex={this.state.currentImage}
                views={this.state.originImgs}
              />
            </Modal>
          </ModalGateway>
        ) : null}
      </GalleryWrapper>
    )
  }
}

export default Gallery
