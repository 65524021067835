import React from "react"

function Image(props) {
  return (
    <img
      onClick={e => props.toggleLightbox(props.imgKey, e)}
      src={props.src}
      className={"grid-item"}
      alt={props.caption}
    />
  )
}

export default Image
